import React, { useEffect } from 'react';
import SECTIONS from 'src/utils/sectionConstants';
import Layout from 'components/Layout';
import HeroSectionCarousel from 'components/HeroSectionCarousel';
import HeroSection from 'components/HeroSection';
import ExpandableList from 'features/expandableList';
import ParagraphWithHeadline from 'features/paragraphWithHeadline';
import DynamicSection from 'features/dynamicSection';
import ListOfCards from 'features/listOfCards';
import SideBySide from 'features/sideBySide';
import RichText from 'features/RichText';
import Teams from 'components/teams';
import Vehicles from 'features/vehicles';
import VehicleSaleSteps from 'components/VehicleSaleSteps';
import FailureModel from 'components/FailureModel';
import MailSubmittedModal from 'components/mailSubmittedModal';
import Modal from 'components/Modal';
import YouTubePlayer from 'features/YoutubePlayer';
import ListOfVenues from 'features/ListOfVenues';
import SEO from 'components/seo';
import { generateContentfulAssetURL } from 'utils/image';
// import { amplitudeEvents, pagesTitles, trackEvent } from 'utils/amplitude';
// import { useAuth0 } from '@auth0/auth0-react';
import { isBrowser } from 'shared/helpers/vehicles';

const WebPage = ({ sections, location, title, metaTitle, metaDescription }) => {
  let seoImage;
  // const { user } = useAuth0();
  // const eventProperties = { email_address: user?.email };
  const pageTitle = title;
  // useEffect(() => {
  //   if (pageTitle === pagesTitles.HOME_PAGE) {
  //     trackEvent(amplitudeEvents.VIEW_HOME_PAGE, eventProperties);
  //   } else if (pageTitle === pagesTitles.CONTACT_US) {
  //     trackEvent(amplitudeEvents.VIEW_CONTACT_US, eventProperties);
  //   } else if (pageTitle === pagesTitles.COLLECTIONS_ESTATES) {
  //     trackEvent(amplitudeEvents.VIEW_COLLECTIONS, eventProperties);
  //   } else if (pageTitle === pagesTitles.REGISTER_TO_BID) {
  //     trackEvent(amplitudeEvents.VIEW_REGISTER_TO_BID, eventProperties);
  //   } else if (pageTitle === pagesTitles.ORDER_CATALOGUE) {
  //     trackEvent(amplitudeEvents.VIEW_ORDER_CATALOGUE, eventProperties);
  //   }
  // }, []);
  if (sections?.length) {
    let heroImage;
    if (sections[0].__typename === SECTIONS.ListOfHeroSections) {
      const heroSections = sections[0].heroSections;
      if (heroSections.length > 0) heroImage = heroSections[0].image;
    }
    if (sections[0].__typename === SECTIONS.HeroImage) {
      const heroSection = sections[0];
      heroImage = heroSection.image;
    }

    seoImage = heroImage
      ? {
          url: generateContentfulAssetURL(heroImage?.file?.url),
          width: heroImage?.file?.details?.image?.width,
          height: heroImage?.file?.details?.image?.height,
        }
      : null;
  }

  const layoutStyle =
    sections?.length &&
    (sections[0].__typename === SECTIONS.ListOfHeroSections ||
      sections[0].__typename === SECTIONS.HeroImage)
      ? {
          transparentHeader: true,
          paddingTop: false,
        }
      : {
          transparentHeader: false,
          paddingTop: true,
        };
  const withOutPadding =
    sections?.length && sections[0].__typename === SECTIONS.RichText;
  const state = location.state || {};

  return (
    <Layout withOutBodyPadding={withOutPadding} {...layoutStyle}>
      <SEO
        image={seoImage}
        title={title}
        link={`${isBrowser() && window.location.origin}`}
        metaTitle={metaTitle}
        description={metaDescription?.childMarkdownRemark?.rawMarkdownBody}
      />
      {state.failedVerification && <FailureModel />}
      {state.RTBEmailSent && (
        <MailSubmittedModal
          isRTB={state.RTBEmailSent}
          isApproved={state.isApproved}
        />
      )}
      {state.emailSent && <MailSubmittedModal />}
      {state.modal?.showModal && (
        <Modal
          title={state.modal.title}
          bodyText={state.modal.bodyText}
          isOpen={state.modal.showModal}
          className={state.modal.className}
        />
      )}
      {sections.map((section, index) => {
        switch (section.__typename) {
          case SECTIONS.CollectionOfLists:
            return (
              <ExpandableList
                key={`${section?.__typename}${index}`}
                section={section}
              />
            );
          case SECTIONS.ListOfHeroSections:
            return (
              <HeroSectionCarousel
                key={`${section?.__typename}${index}`}
                heroSections={section?.heroSections}
                isTextWithOrPrefix={true}
              />
            );
          case SECTIONS.HeroImage:
            const {
              image,
              title,
              callToAction,
              description,
              subTitle,
            } = section;
            return (
              <HeroSection
                key={`${section?.__typename}${index}`}
                image={image}
                title={title}
                subTitle={subTitle}
                callToActions={callToAction}
                description={description?.childMarkdownRemark?.html}
                isTextWithOrPrefix={true}
                pageTitle={pageTitle}
              />
            );
          case SECTIONS.DynamicSections.type:
            return (
              <DynamicSection
                key={`${section?.__typename}${index}`}
                section={section}
              />
            );
          case SECTIONS.ParagraphWithHeadline:
            return (
              <ParagraphWithHeadline
                key={`${section?.__typename}${index}`}
                styleType={section?.style}
                ismarkdown={true}
                description={section?.paragraph?.childMarkdownRemark?.html}
                headline={section?.headline}
                callToAction={
                  section?.callToAction?.length && section.callToAction
                }
              />
            );
          case SECTIONS.SideBySide:
            return (
              <SideBySide
                key={`${section?.__typename}${index}`}
                sections={section?.sideBySide}
                cta={section?.callToActionList?.cta}
              />
            );

          case SECTIONS.ListOfCards:
            return (
              <ListOfCards
                key={`${section?.__typename}${index}`}
                cards={section?.cards?.length ? section.cards : []}
                title={section?.header}
              />
            );
          case SECTIONS.Card:
            return (
              <ListOfCards
                key={`${section?.__typename}${index}`}
                cards={[section]}
                title={section?.header}
              />
            );
          case SECTIONS.RichText:
            return (
              <RichText
                key={`${section?.__typename}${index}`}
                json={section?.richText?.json}
                headline={section?.headline}
                location={location}
                showSideIndex={section?.showSideIndex}
              />
            );
          case SECTIONS.ListOfContacts:
            return (
              <Teams
                title={section?.title}
                key={`${section?.__typename}${index}`}
                contacts={
                  section?.childContentfulWebListOfContactsContactsJsonNode
                    ?.entries
                }
              />
            );
          case SECTIONS.ListOfVenues:
            return (
              <ListOfVenues
                venuesEntries={
                  section?.childContentfulWebListOfVenuesVenuesJsonNode?.entries
                }
                key={`${section?.__typename}${index}`}
              />
            );
          case SECTIONS.VehicleCatalogOptions:
            return (
              <Vehicles
                key={`${section?.__typename}${index}`}
                location={location}
                section={section}
              />
            );
          case SECTIONS.Timeline:
            return (
              <VehicleSaleSteps
                steps={section?.steps}
                key={`${section?.__typename}${index}`}
              />
            );
          case SECTIONS.VideoMedia:
            return (
              <YouTubePlayer
                key={`${section?.__typename}${index}`}
                url={section?.videoUrl}
              />
            );
          default:
            return '';
        }
      })}
    </Layout>
  );
};

export default WebPage;
