import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { GET_ORDER_A_CATALOGUE_DATA } from 'services/graphql/queries/order-a-catalogue/index.js';
import { useAuthenticatedQuery } from 'services/graphql/hooks';
import { useAuth0 } from '@auth0/auth0-react';
import Button from 'components/Button';
import AuctionsCardList from 'components/AuctionsCatalogCardList';
import Spinner from 'components/Spinner';
import { Modal } from 'reactstrap';
import styles from './orderCatalog.module.scss';
import { useStaticQueryGetAuctionCatalogue } from 'services/graphql/queries/catalogue';
import CatalogYearSubscription from './CatalogYearSubscription';
import { SHIPPING_OPTIONS } from 'utils/orderACatalogueConstants';
import { partitionAuctions } from './util';
// import { amplitudeEvents, trackEvent } from 'utils/amplitude';
const minimumQuantity = 1;

const localStorageRef =
  typeof localStorage !== `undefined` ? localStorage : null;

const OrderACatalogue = () => {
  const {
    catalogueAuctions,
    allContentfulCatalogueYearSubscription,
  } = useStaticQueryGetAuctionCatalogue();

  const { isAuthenticated, user } = useAuth0();
  const { loading, data } = useAuthenticatedQuery(GET_ORDER_A_CATALOGUE_DATA, {
    skip: !isAuthenticated,
  });

  const getInitialState = () => {
    if (localStorageRef) {
      let cart = localStorage.getItem('CartData') || '[]';
      return JSON.parse(cart);
    } else {
      return [];
    }
  };
  const [cart, setCart] = useState(getInitialState());
  const [modalCarouselIsOpen, setModalCarousel] = useState(false);

  const formattingCart = cart => {
    return cart.map((item, index) => {
      return {
        id: index,
        name: item.name,
        price: item.price * (item.quantity || 1) + (item.shippingPrice || 0),
        quantity: item.quantity,
        note: item.shippingType || '',
      };
    });
  };

  const setCartData = cart => {
    if (localStorageRef) {
      localStorage.setItem('CartData', JSON.stringify(cart));
      setCart(cart);
    }
  };
  const addToCart = (auction, quantity, shipping) => {
    setCartData([
      ...cart,
      {
        name: auction.name,
        price: auction.price,
        shippingType: SHIPPING_OPTIONS[shipping - 1].label,
        shippingPrice: SHIPPING_OPTIONS[shipping - 1].price * quantity,
        quantity: quantity,
      },
    ]);
    // setReset(false);
    setModalCarousel(true);
  };

  const [formattedAuctions, setFormattedAuctions] = useState(
    partitionAuctions(catalogueAuctions, data?.profile?.buyerStatus)
  );

  useEffect(() => {
    if (!loading && data)
      setFormattedAuctions(
        partitionAuctions(catalogueAuctions, data?.profile?.buyerStatus)
      );
  }, [loading]);

  return (
    <div>
      {loading && <Spinner />}
      <Modal
        isOpen={modalCarouselIsOpen}
        toggle={() => {
          setModalCarousel(!modalCarouselIsOpen);
        }}
      >
        <div className={styles.orderCatalogModalBody}>
          <h2>Catalogue added to cart successfully</h2>
          <Button
            handleClick={() => {
              setModalCarousel(!modalCarouselIsOpen);
            }}
            type={'app-transparent-blue-button'}
          >
            Close
          </Button>
        </div>
      </Modal>
      <div className={styles.catalogueSection}>
        <div className={styles.headingSection}>
          <div>
            <div>
              <h1>ORDER A CATALOGUE</h1>
            </div>
            <Button type="">
              <span
                onClick={() => {
                  if (isAuthenticated) {
                    // trackEvent(amplitudeEvents.VIEW_CART_CATALOGUE, {
                    //   email_address: user?.email,
                    // });
                    navigate('/auth/checkout', {
                      state: {
                        cart: formattingCart(cart),
                      },
                    });
                  } else {
                    navigate('/auth/login');
                  }
                }}
              >
                View Shopping Cart &nbsp;&nbsp;
                {cart && cart.length > 0 ? (
                  <label className={`label success ${styles.label}`}>
                    &nbsp;{cart.length}&nbsp;
                  </label>
                ) : (
                  <></>
                )}
              </span>
            </Button>
          </div>
        </div>
        <CatalogYearSubscription
          contentfulYearSubscription={
            allContentfulCatalogueYearSubscription.nodes
          }
          minimumQuantity={minimumQuantity}
          setCartData={setCartData}
          cart={cart}
          setModalCarousel={setModalCarousel}
        />
        <div>
          <AuctionsCardList
            past={formattedAuctions?.pastAuctions || []}
            upcoming={formattedAuctions?.newAuctions || []}
            title={'Individual Catalogues'}
            handleClick={addToCart}
            minimumQuantity={minimumQuantity}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderACatalogue;
